import BasicLayout from "main/layouts/BasicLayout/BasicLayout";

export default function AdminStudentsCreatePage() {
  return (
    <BasicLayout>
      <div className="pt-2">
        <h1>Create page not yet implemented</h1>
      </div>
    </BasicLayout>
  );
}
